@import _variables

body
    background: #F6F6F6

.homepage
    background: #F6F6F6
    margin: 0
    max-width: 100vw
    position: relative
    @extend %roboto
    &-header
        height: 120vh
        padding: 31px 0 100px 0
        position: relative
        margin: 0
        background: linear-gradient(180deg, rgba(246, 246, 246, 0) 72.92%, #F6F6F6 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.8) -3.22%, rgba(0, 0, 0, 0) 118.7%), url('../media/img/header-bg.png') no-repeat center
        background-size: cover
        &-form
            background: #ffffff
            border-radius: 12px
            padding: 30px 30px 50px 30px
            max-width: 700px
            h1
                font-size: 20px
                font-weight: 600
                color: #051C39
                margin-bottom: 30px
            &-wrapper
                display: flex
                flex-wrap: wrap
                justify-content: space-between
                &-outer
                    display: flex
                    flex-wrap: nowrap
                    justify-content: center
                label
                    display: flex
                    flex-direction: column
                    position: relative
                    width: calc(50% - 25px)
                    font-size: 12px
                    font-weight: 500
                    color: #929498
                    margin-bottom: 32px
                    input
                        font-size: 14px
                        font-weight: 500
                        color: #929498
                        border: none
                        border-bottom: 2px solid #92949881
                        margin-top: 8px
                        transition: all 0.3s cubic-bezier(0.53, 0.88, 0.5, 0.7)
                        padding: 2px 0
                        appearence: none
                        -webkit-appearance: none
                        -moz-appearance: none 
                        &::placeholder
                            font-size: 14px
                            font-weight: 500
                            color:#929498
                        &:focus, &:hover
                            outline: none
                            color: #051C39
                            border-bottom-color: #377DFF
                            appearence: none
                            -webkit-appearance: none
                            -moz-appearance: none 
                    &:last-child
                        margin: 0
                    select
                        font-size: 14px
                        font-weight: 500
                        color: #929498
                        border: none
                        border-bottom: 1px solid #929498
                        outline: none
                        appearence: none
                        -webkit-appearance: none
                        -moz-appearance: none 
                        transition: all 0.3s cubic-bezier(0.53, 0.88, 0.5, 0.7)
                        margin-top: 8px
                        &:focus, &:hover
                            color: #051C39
                            cursor: pointer
                    .select-arrow
                        position: absolute
                        z-index: 0
                        right: 4.6px
                        width: 9px
                        bottom: 5px
                        border: 8px solid
                        border-color: white white #929498 white
                        border-right: 0
                &-inner
                    width: 50%
                    display: flex
                    justify-content: space-between
                    align-items: center
                    button
                        font-size: 18px
                        font-weight: 500
                        padding: 14px 65px
                        border-radius: 12px
            &-pc-section
                &-first, &-second
                    width: 1000px
                    height: 56px
                    button
                        width: 196px
                        border-radius: 0 12px 12px 0
                    select
                        width: 190px
                        border: 1px solid #f6f6f6
                        background: #f6f6f6
                        outline: 0
                        box-shadow: none
                        padding-left: 20px
                        &:hover
                            cursor: pointer
                    select[name="diameter"]
                        border-radius: 12px 0 0 12px
                    input[name="choice"]
                        width: 418px
                    input[name="name"]
                        width: 315px
                        border-radius: 12px 0 0 12px
                        border-right: 1px solid #d9d9d9
                    input[name="phone"]
                        width: 488px
                    input
                        background: #ffffff
                        border: none
                        padding-left: 32px
                    &.opened
                        display: flex
                    &.closed
                        display: none
    &-form-wrapper
        display: flex
        flex-direction: column
        justify-content: space-between
        align-items: center
        h1
            @extend %roboto
            font-size: 56px
            font-weight: 900
            color: #ffffff
            margin: 0 0 8px 0
        h3
            @extend %roboto
            font-size: 27px
            font-weight: 700
            color: #ffffff
            margin: 8px 0 24px 0
    &-product-list
        position: relative
        padding: 100px 0 0 0
        hr
            background: #004BBC
            height: 2px
            opacity: 1
            margin: 70px 0
        &-title
            font-size: 32px
            font-weight: 600
            padding: 100px 0 30px 0
        &-item
            padding: 30px 0
            &.row
                position: inherit
                max-width: 100%
            .col-3
                padding-right: 0 !important
            &-form-wrapper
                position: fixed
                display: none
                top: 0
                left: 0
                width: 100vw
                height: 100vh
                padding: 20px 20px
                justify-content: center
                align-items: center
                z-index: 1000
                &-cross
                    height: 20px
                    width: 20px
                    position: absolute
                    top: 31px
                    right: 20px
                    &::after, &::before
                        content: ''
                        position: absolute
                        width: 23px
                        height: 2px
                        background: #0d6efd
                        top: 50%
                        left: 50%
                    &::after
                        transform: translate(-50%, -50%) rotate(-45deg)
                    &::before
                        transform: translate(-50%, -50%) rotate(45deg)
                    &:hover
                        cursor: pointer
                form
                    max-width: 600px
                &#opened
                    display: flex
                    background: #000000c5
            &-card
                background: #ffffff
                border-radius: 24px
                padding: 30px 50px 30px 37px
                &-images-carousel
                    margin-bottom: 83px
                    .carousel-inner
                        border-radius: 12px
                    .carousel-control-next
                        opacity: 1
                        right: -25px
                        &-icon
                            background: url(../media/svg/carousel-arrow-right.svg) no-repeat center, #ffffff
                            width: 50px
                            height: 50px
                            border-radius: 50%
                            box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.062)
                            transition: all 0.3s cubic-bezier(0.53, 0.88, 0.5, 0.7)
                            &:hover
                                box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.11)
                    .carousel-control-prev
                        opacity: 1
                        left: -25px
                        &-icon
                            background: url(../media/svg/carousel-arrow-left.svg) no-repeat center, #ffffff
                            width: 50px
                            height: 50px
                            border-radius: 50%
                            box-shadow: -5px 5px 8px rgba(0, 0, 0, 0.062)
                            transition: all 0.3s cubic-bezier(0.53, 0.88, 0.5, 0.7)
                            &:hover
                                box-shadow: -5px 5px 8px rgba(0, 0, 0, 0.11)
                    .carousel-item
                        img
                            object-fit: cover
                    .carousel-indicators
                        bottom: -99px
                        justify-content: space-between
                        button
                            position: relative
                            width: 83px
                            height: 63px
                            img
                                width: 100%
                                height: 100%
                                position: absolute
                                top: 0
                                left: 0
                                object-fit: cover
                                border-radius: 12px
                &-description
                    font-size: 14px
                    font-weight: 400
                    line-height: 18px
                    margin: 0
                    padding-top: 15px
                    height: 0
                    overflow: hidden
                    opacity: 0
                    transition: height .5s cubic-bezier(0.53, 0.88, 0.5, 0.7), opacity 0.2s cubic-bezier(0.53, 0.88, 0.5, 0.7) .2s
                    &.toggled
                        height: auto
                        opacity: 1
                    &-toggle-btn
                        border-bottom: 1px dashed #377DFF
                        font-size: 14px
                        font-weight: 400
                        color: #377DFF
                        &:hover
                            cursor: pointer
                &-specs
                    h1
                        font-size: 32px
                        font-weight: 600
                        margin: 0
                    &-wrapper
                        display: flex
                        justify-content: space-between
                        padding-top: 35px
                        ul
                            li
                                font-size: 14px
                                font-weight: 400
                                line-height: 16px
                                padding: 15px 0
            &-price
                background: #ffffff
                border-radius: 24px
                padding: 50px 30px 30px 30px
                hr
                    display: none
                h1
                    font-size: 32px
                    font-weight: 600
                    text-align: center
                    margin: 0
                    span
                        font-size: 14px
                        font-weight: 400
                &-wrapper
                    display: flex
                    justify-content: space-between
                    ul
                        padding: 48px 0 50px 0
                        li
                            font-size: 14px
                            font-weight: 400
                            line-height: 16px
                            padding: 15px 0
                &-button
                    font-size: 20px
                    font-weight: 400
                    line-height: 23px
                    padding: 17px 43px
                    border-radius: 12px
                    span
                        display: none
                    &-wrapper
                        width: 100%
                        text-align: center
    &-about
        position: relative
        &-cardlist
            display: flex
            flex-wrap: wrap
            position: relative
            padding: 0 0 40px 0
            &-item
                display: flex
                flex-direction: column
                height: 315px
                width: calc(50% - 30px)
                margin: 15px
                border-radius: 24px
                padding: 50px 30px 30px 30px
                &:nth-child(1)
                    background: url('../media/svg/builder.svg') no-repeat left, #ffffff
                &:nth-child(2)
                    background: url('../media/svg/bulldozer.svg') no-repeat left, #ffffff
                &:nth-child(3)
                    background: url('../media/svg/hand.svg') no-repeat left, #ffffff
                &:nth-child(4)
                    background: url('../media/svg/file.svg') no-repeat left, #ffffff
                h1
                    font-size: 24px
                    font-weight: 500
                    color: #000000
                    height: 50%
                ul
                    list-style-type: disc
                    padding-left: 18px
                    height: 50%
                    li
                        font-size: 14px
                        font-weight: 400
                        color: #000000
        &-contact-us
            position: relative
            max-width: 100%
            padding: 0
            .col-7
                display: flex
                justify-content: space-between
                padding-right: 0
            .col-5
                padding-left: 0
            &-operator
                &-info
                    position: absolute
                    top: 40%
                    left: 20px
                &-contact
                    padding-top: 100px
                    h1
                        font-size: 36px
                        font-weight: 700
                        color: #051C39
                        margin-bottom: 51px
                    p
                        font-size: 20px
                        font-weight: 400
                        color: #929498
                        margin-bottom: 60px
                    a
                        font-size: 32px
                        font-weight: 600
                        color: #051C39
                    form
                        display: flex
                        margin-top: 45px
                        input[type="text"]
                            border: none
                            background: #ffffff
                            padding: 14px 22px
                            font-size: 16px
                            font-weight: 400
                            color: #272727
                            border-radius: 8px
                            width: 100%
                            margin-right: 10px
                            &:placeholder
                                font-size: 16px
                                font-weight: 400
                                color: #D7D7D7
                        button
                            font-size: 16px
                            font-weight: 400
                            border-radius: 8px
                            padding-right: 42px
                            padding-left: 42px
            &-info
                background: #377DFF
                &-work-expirience
                    display: flex
                    justify-content: space-around
                    padding: 49px 0
                    h1
                        font-size: 56px
                        font-weight: 700
                        color: #ffffff
                        margin-bottom: 15px
                    p
                        font-size: 18px
                        font-weight: 500
                        color: #ffffff
                        margin: 0
                &-services
                    padding: 100px 0 40px 0
                    h1
                        font-size: 32px
                        font-weight: 600
                        color: #ffffff
                        margin: 0 0 59px 0
                    h2
                        font-size: 24px
                        font-weight: 600
                        color: #82AEFF
                        margin-bottom: 20px
                    ul
                        margin-bottom: 60px
                        li
                            font-size: 18px
                            font-weight: 500
                            color: #ffffff
                            margin: 0
                            padding: 10px 0
        &-company
            &-info
                padding: 35px 0 0 0
                h1
                    font-size: 32px
                    font-weight: 600
                    color: #051C39
                    margin-bottom: 30px
                p
                    font-size: 14px
                    font-weight: 400
                    color: #374E6B
                    margin-bottom: 40px
                    max-width: 692px
                    line-height: 20px
            &-contacts
                padding: 0 0 98px 0
                h2
                    font-size: 24px
                    font-weight: 600
                    color: #051C39
                    margin-bottom: 30px
                h3
                    font-size: 27px
                    font-weight: 600
                    color: #377DFF
                    margin-bottom: 30px
                    max-width: 478px
                .col-6
                    display: flex
                    flex-direction: column
                    justify-content: space-between
                &-wrapper
                    display: flex
                    flex-wrap: wrap
                    div
                        width: 50%
                        &.wrapper-exclusive
                            width: 100%
                    p
                        font-size: 14px
                        font-weight: 400
                        color: #929498
                        margin-bottom: 20px
                        line-height: 20px
                    a, h2
                        font-size: 27px
                        font-weight: 600
                        color: #377DFF
                        margin-bottom: 30px
                        max-width: 478px
                
                            
@import _variables
@import _responsive
@import _temp

a
  text-decoration: none

ul
  list-style-type: none
  padding: 0
  
body
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  overflow-x: hidden

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

.navbar-top-static
  z-index: 1
  position: inherit
  .navbar-collapsible
    z-index: 9999 !important
    width: 100vw
    height: 100vh
    position: fixed
    background: #ffffff
    top: -100vh
    left: 0
    &-navcross
      height: 20px
      width: 20px
      position: relative
      &::after, &::before
        content: ''
        position: absolute
        width: 23px
        height: 2px
        background: #0d6efd
        top: 50%
        left: 50%
      &::after
        transform: translate(-50%, -50%) rotate(-45deg)
      &::before
        transform: translate(-50%, -50%) rotate(45deg)
      &:hover
        cursor: pointer
    &-wrapper
      position: inherit
      display: flex
      flex-direction: column
      align-items: center
      justify-content: space-around
      padding: 40px 20px
      width: 100%
      height: 60%
      a
        padding: 0
        font-size: 32px !important
      p
        color: #929498
        font-size: 14px
      img
        height: 20px
    div:nth-child(1)
      padding: 35px 36px
      width: 100%
      display: flex
      align-items: flex-start
      img
        &:hover
          cursor: pointer
    &.toggled
      top: 0
  .navbar-wrapper
    display: flex
    position: static
    width: 100%
    justify-content: space-between
    align-items: flex-start
    .navbar-logo
      img
        max-height: 48px
      h1
        @extend %mont
        font-size: 24px
        color: #ffffff
        margin: 0
      p
        @extend %roboto
        font-weight: 400
        font-size: 12px
        color: #3F8CFF
    .navbar-links
      display: flex
      align-items: flex-end
      .navbar-link
        @extend %roboto
        font-size: 16px
        color: #ffffff
        margin: 0
        line-height: 24px
        &.base-link
          margin: 0 30px 0 30px
      .link-box
        margin: 0 30px 0 30px
        display: flex
      .dropdown-arrow
        margin-bottom: 0px
        &:hover
          cursor: pointer
    .navbar-info
      position: relative
      a
        @extend %roboto
        font-size: 27px
        font-weight: 700
        color: #ffffff
      p
        @extend %roboto
        font-size: 12px
        font-weight: 400
        color: #EBEBEB
        margin: 10px 0 13px 0
      .navbar-info-button
        font-size: 14px
        font-weight: 400
        line-height: 14px
        padding: 8px 38px
        border-radius: 4px
    .navbar-burger
      display: none
      &:hover
        cursor: pointer

.footer
  background: #ffffff
  max-width: 100vw
  width: 100vw
  margin: 0
  .col-7
    padding: 99px 0 0 0
    h1
      font-size: 32px
      font-weight: 900
      color: #051C39
    p
      font-size: 16px
      font-weight: 500
      color: #929498
      max-width: 349px
  .col-5
    padding: 108px 0 0 0
    h2
      font-size: 24px
      font-weight: 600
      color: #051C39
  &-wrapper
    display: flex
    position: relative
    flex-wrap: wrap
    margin-top: 36px
    div
      width: 50%
      margin-bottom: 57px
      p
        font-size: 16px
        font-weight: 400
        color: #929498
        margin-bottom: 10px
      a
        font-size: 16px
        font-weight: 600
        color: #051C39
      &:nth-child(3)
        width: 100% !important
        div
          width: 100% !important
        a
          color: #377DFF
  &-copyright
    padding: 0px 50px 50px 50px
    div
      display: flex
      padding-top: 20px
      justify-content: space-between
      border-top: 1px solid #9294989a
    span
      font-size: 14px
      font-weight: 400
      color: #929498
    
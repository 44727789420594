@import _variables

@media screen and (max-width: 1380px) and (min-width: 768px)
    .responsive-wrapper
        &.rw-about-company, &.rw-footer, &.rw-about-info
            .content-align
                max-width: 768px !important
    .homepage
        &-about
            &-company-contacts
                .col-6
                    width: 100%
                    &:nth-child(2)
                        margin-top: 40px
            &-contact-us-info-work-expirience
                flex-wrap: wrap
                &-item
                    width: 50%
                    margin-top: 10px
                    &:nth-child(even)
                        margin-top: 40px
                        padding-left: 20px

@media screen and (max-width: 1170px)
    .toggle-btn-wrapper
        padding-left: 20px
    .homepage-header-form-pc
        display: none
    .homepage-header-form-wrapper
        label
            width: calc(50% - 20px) !important
            &:nth-child(n+1)
                margin-right: 20px !important
    .homepage-product-list
        padding-top: 0 !important
        &-title
            padding-top: 0 !important
    .homepage-product-list-item
        flex-direction: column !important
        position: relative
        max-width: 100%
        width: 100% !important
        --bs-gutter-x: 0 !important
        .carousel-indicators
            display: none
        .col-9, .col-3
            width: 100% !important
            padding: 0
        &-form-wrapper
            position: fixed
            display: none
            top: 0
            left: 0
            width: 100vw
            height: 100vh
            padding: 20px 20px
            justify-content: center
            align-items: center
            z-index: 1000
            img
                position: absolute
                right: 20px
                top: 20px
            form
                max-width: 900px
            &#opened
                display: flex
                background: #000000c5
        &-card
            width: 100% !important
            border-radius: 12px 12px 0 0 !important
            padding: 30px 0 0 0 !important 
            &-description
                padding: 0px 20px 0px 0 !important
                padding-left: 20px !important
            .row
                .carousel-control-next, .carousel-control-prev
                    display: none
                .col-6
                    width: 100% !important
                    .carousel-inner
                        margin-top: 43px
                        border-radius: 0 !important
            &-images-carousel
                margin-bottom: 0 !important
            &-specs
                h1
                    position: absolute
                    top: 60px
                    left: 20px
                    font-size: 20px !important
                &-wrapper
                    margin-top: 10px
                    padding: 0 20px 0 20px !important
                    li
                        padding: 11px 0 !important
        &-price
            padding: 20px 0px 0px 0px !important
            border-radius: 0 0 12px 12px !important
            hr
                display: block !important
                background: #929498 !important
                opacity: .5 !important
                margin: 0 20px 0 20px !important
            h1
                display: none !important
            &-button
                width: 100%
                border-radius: 0 0 12px 12px !important
                span
                    display: inline-block !important
            &-wrapper
                padding: 20px !important
                ul
                    padding: 0 !important
                    color: #929498 !important
                    li
                        padding: 11px 0 !important
    .footer-wrapper
        div
            width: 100% !important
            margin-bottom: 30px !important
    .navbar-top-static
        .navbar-wrapper
            .navbar-links, .navbar-info
                display: none !important
            .navbar-burger
                display: block !important
    .homepage
        &-about-contact-us
            display: none
        &-form-wrapper
            h1
                font-size: 27px !important


@media screen and (max-width: 767px) and (min-width: 360px)
    .responsive-wrapper
        &.rw-about-company, &.rw-footer, &.rw-about-info, &.rw-about-company
            .content-align
                max-width: 480px !important
    .homepage-product-list-item-form-wrapper-cross
        top: 10px !important
    .footer
        .col-7, .col-5
            width: 100% !important
        &-copyright
            padding: 0 0 50px 0 !important
            span
                font-size: 9px !important
    .homepage
        &-about
            &-cardlist-item
                width: 100% !important
                height: auto !important
                h1
                    font-size: 20px !important
                li
                    font-size: 14px !important
                background: #ffffff !important
            &-company-contacts
                .col-6
                    width: 100% !important
                    margin-top: 30px
                &-wrapper
                    div
                        margin-bottom: 12px
                        width: 100% !important
                        h2
                            font-size: 18px
                        p
                            font-size: 12px
                            margin-bottom: 8px
                        a
                            font-size: 18px
            &-contact-us-info
                &-services
                    h1
                        font-size: 24px !important
                    h2
                        font-size: 18px !important
                    li
                        font-size: 14px !important
                &-work-expirience
                    flex-wrap: wrap
                    &-item
                        width: 50%
                        margin-top: 10px
                        &:nth-child(even)
                            margin-top: 40px
                            padding-left: 20px

@media screen and (max-width: 890px)
.responsive-wrapper
    width: 100%
    height: 100%
    position: relative
    &.rw-header, &.rw-productlist, &.rw-about-contact, &.rw-about-info, &.rw-about-company
        display: flex
        justify-content: center
        .content-align
            position: relative
            max-width: 1440px
            width: 100% !important
            padding: 0 20px
            display: flex
            flex-direction: column
            justify-content: space-between
    &.rw-productlist, &.rw-about-contact, &.rw-about-company
        background: #F6F6F6
    &.rw-about-info
        background: #377DFF
    &.rw-footer
        display: flex
        justify-content: center
        .content-align
            max-width: 1440px
            width: 100%
            padding: 0 20px
            .col-7, .col-5
            
@media screen and (max-width: 890px) and (min-width: 350px)
    .homepage-header-form
        padding: 30px 0 0 0 !important
        h1
            padding: 0 20px
        &-wrapper
            label
                padding: 0 20px !important
                width: 100% !important
                .select-arrow
                    right: 20px !important
            &-inner
                width: 100% !important
                button
                    width: 100%
                    border-radius: 0 0 12px 12px !important
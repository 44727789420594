.button-blue
        background: #377DFF
        @extend %roboto
        color: #ffffff
        transition: background .3s cubic-bezier(0.53, 0.88, 0.5, 0.7)
        border: none
        &:hover
          background: #3F8CFF
        &:focus
          background: #176ae6
          box-shadow: inset -8px -8px 12px rgba(255, 255, 255, 0.12), inset 8px 8px 16px rgba(0, 0, 0, 0.15)